$ex-heading-05: expressive-heading-05;
@include carbon--colors();

.sc--main-wrapper {
  margin-top: $carbon--spacing-09;
  margin-bottom: $carbon--spacing-09;
}

.sc--main-title {
  margin-bottom: $layout-05;
  padding-top: 2rem;
}

.sc--nav-item {
  position: relative;
  &.active::after {
    position: absolute;
    bottom: -2px;
    width: 100%;
    height: 3px;
    background: $carbon--blue-60;
    display: block;
    content: "";
    left: 0;
    top: auto;
  }
}

h1 {
  @include carbon--type-style(productive-heading-07, true);
}

.sc--current-page .bx--link {
  color: inherit !important;
}

@media (max-width: 66rem) {
  .bx--header__nav {
    display: block;
  }
}

.d-flex {
  display: flex;
}

body > iframe[style*='2147483647']{
  display: none;
}