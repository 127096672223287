$prefix: 'sc--manage-users';

body.#{$prefix}-open{
	overflow: hidden;
}

.#{$prefix} {
	background: #ffffff;
	display: flex;
	position: fixed;
	width: 100%;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 2;
	padding-top: 3rem;
	bottom: 0;
	top: 3rem;
	justify-content: flex-start;
	flex-direction: column;
	overflow-y: scroll;
	&-intro {
		align-items: center;
	}
	&-button-wrapper {
		button{
			min-width: 200px;
			&:first-of-type{
				margin-right: 2rem;
			}
		}
	}

	&-intro {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: $layout-04;
	}
	&-progress-indicator-wrapper {
		width: 100%;
		margin-bottom: $layout-03;
	}

	&-step {
		display: flex;
		justify-content: space-between;
		margin-left: -2rem;
		margin-right: -2rem;
	}
}

.sc--body {
	@include carbon--type-style(body-short-01, true);
	&.description {
		margin-top: $spacing-05;
	}
}

.#{$prefix}-details{
	display: flex;
	justify-content: space-between;
	margin-left: -2rem;
	margin-right: -2rem;
}

.#{$prefix}-column {
	padding-left: 2rem;
	padding-right: 2rem;
	flex: 0 1 auto;
	width: 100%;
	margin-bottom: 2rem;
	> div:not(.sc--details-title){
		margin-bottom: $spacing-06;
	}
	.title{
		margin-bottom: $spacing-06;
		@include carbon--type-style(productive-heading-03, true);
	}

}

.#{$prefix}-progress-indicator{
	.bx--progress-step--current{
		.bx--progress-label{
			font-weight: bold;
		}
	}
	.bx--progress-step--disabled{
		pointer-events: none;
	}
}

.#{$prefix}{
	&-action-radio-buttons{
		.#{$prefix}-action-radio-button{
			margin-bottom: $spacing-05!important;
			label{
				align-items: flex-start;
			}
		}
	}
	.unordered-list{
		margin-top: $spacing-02;
		margin-left: $spacing-05;
	}
	&-radio-label-text-title{
		display:block;
		@include carbon--type-style(body-short-02, true);
		font-weight: bold;
		margin-bottom: $spacing-02;
	}
}

.#{$prefix}-notification {
	max-width: none;
}
